:root {
  --spacing: 80px;

  --grey: #828282;
  --light: #BDBDBD;
  --blue: #2f80ed;
}

body { 
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
