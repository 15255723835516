.container {
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.form {
  width: 100%;
  min-width: 280px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  word-break: keep-all;
}

@media (max-width: 960px) {
  .container {
    flex-direction: column;
    gap: 15px;
    width: 90%;
    align-items: center;
  }

  .form {
    max-width: 100%;
  }
}
