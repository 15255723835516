.container {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 50fr 50fr;
  column-gap: 20px;
  row-gap: 30px;
}

.title {
  grid-area: 1 / 1 / 2 / 2;
  font-size: 24px;
  color: #828282;
}

.caption {
  grid-area: 2 / 1 / 3 / 2;
}

.button {
  grid-area: 3 / 1 / 4 / 2;
  color: var(--blue);
  cursor: pointer;

  display: flex;
  gap: 5px;
  align-items: flex-end;
}

.carousel {
  grid-area: 1 / 2 / 4 / 3;
  height: 350px;
  width: 100%;
}

@media (max-width: 960px) {
  .container {
    grid-template-rows: auto 350px auto auto;
    grid-template-columns: 100fr;
  }

  .title {
    grid-area: 1 / 1 / 2 / 2;
  }

  .caption {
    grid-area: 3 / 1 / 4 / 2;
  }

  .button {
    grid-area: 4 / 1 / 5 / 2;
  }

  .carousel {
    grid-area: 2 / 1 / 3 / 2;
  }
}
