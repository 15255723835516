.container {
  display: flex;
  align-items: center;
  height: 100px;
  width: 100%;
  margin-bottom: 20px;
}

.navbar {
  display: flex;
  gap: 15px;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
}

.linksContainer {
  display: flex;
  gap: 30px;
}

.burgerContainer {
  display: none;
}

@media (max-width: 960px) {
  .container {
    justify-content: center;
  }
  .navbar {
    width: 90%;
  }
  .linksContainer {
    display: none;
  }
  .burgerContainer {
    display: initial;
  }
}

.logo {
  white-space: nowrap;
  color: #828282;
  font-size: 24px;
}

.logo > div {
  display: inline;
  color: #2f80ed;
}
