.layout {
  display: flex;
  justify-content: center;
  font-size: 18px;
  line-height: 150%;
}

.layout > div {
  width: 80%;
  max-width: 1200px;
}

.main {
  min-height: 100vh;
}

@media (max-width: 960px) {
  .layout > div {
    width: 100%;
  }
}

.outlet {
  display: flex;
  flex-direction: column;
  gap: var(--spacing);
}
