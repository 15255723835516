.container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 100%;
  box-sizing: border-box;
}

.form {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media (max-width: 960px) {
  .container {
    flex-direction: column-reverse;
    gap: 15px;
    width: 90%;
    align-items: center;
  }
}

.wrapper {
  width: inherit;
  display: flex;
  justify-content: space-between;
}

.wrapper > div:first-child {
  position: relative;
  top: 8px;
  width: 16px;
  margin-right: 16px;
}

.note {
  padding-left: 13px;
  line-height: 150%;
  font-size: 14px;
}
