.hoverUnderlineAnimation {
  display: inline-block;
  position: relative;
}

.hoverUnderlineAnimation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #2f80ed;
  transform-origin: bottom right;
  transition: transform 0.25s ease;
}

.hoverUnderlineAnimation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.linkActive:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #2f80ed;
  transform-origin: bottom right;
  transform: scaleX(1);
  transform-origin: bottom left;
}
