.container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 150vh;
  background-color: #00000040;
  z-index: 999;
}

.menu {
  position: fixed;
  right: 0;
  top: 0;
  background-color: white;
  min-width: 200px;
  height: 100vh;
  padding: 10px;
}

.link {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: 200ms;
}

.link:hover {
  color: var(--blue);
  transition: 200ms;
}

.linkActive {
  color: var(--blue);
}

.close {
  width: 24px;
  height: 24px;
  margin-bottom: 20px;
  border-radius: 50%;
  padding: 3px;
  transition: 200ms;
}

.close:hover {
  background-color: #00000010;
  transition: 200ms;
}

.close:active {
  background-color: #00000030;
  transition: 200ms;
}
