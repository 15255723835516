.container {
  width: 100%;
  font-size: 12px;
  color: var(--grey);
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  white-space: nowrap;
  padding-top: 10px;
}

.container > * {
  display: flex;
  align-items: center;
  gap: 5px;
}

.icon {
  width: 18px;
}

.container a {
  color: var(--grey);
  text-decoration: none;
}

.container a:hover {
  color: var(--blue);
  transition: 500ms;
}

@media (max-width: 960px) {
  .container {
    justify-content: center;
  }
}

@media (max-width: 400px) {
  .container {
    visibility: hidden;
  }
}
