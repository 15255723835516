.textarea {
  width: 100%;
  padding: 7px 10px;
  border: 1px solid var(--light);
  border-radius: 5px;
  outline: transparent;
  resize: none;
  font-size: 18px;
  font-family: 'Inter';
}
