.open {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding: 3px;
  transition: 200ms;
}

.open:hover {
  background-color: #00000010;
  transition: 200ms;
}

.openBurger:active {
  background-color: #00000030;
  transition: 200ms;
}
