.container {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container > div {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.error {
  font-size: 64px;
  font-weight: bolder;
  margin-bottom: 25px;
}

.notFound {
  font-size: 24px;
  font-weight: bolder;
}

.backButton {
  cursor: pointer;
  color: var(--blue)
}

.hoverUnderlineAnimation {
  display: inline-block;
  position: relative;
}

.hoverUnderlineAnimation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #2f80ed;
  transform-origin: bottom right;
  transition: transform 0.25s ease;
}

.hoverUnderlineAnimation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
