.container {
  width: 100%;
  height: 40px;
  padding-bottom: 10px;
}

.inputContainer {
  width: inherit;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  height: inherit;

  border: 1px solid var(--light);
  border-radius: 5px;
}

.input {
  background-color: transparent;

  padding: 0px 10px;

  width: 100%;
  height: inherit;

  border: 0;
  outline: 0;

  font-size: 18px;
  align-self: flex;
}

.alert {
  width: inherit;
  border-color: transparent;
  outline: 1px solid red !important;
}

.alertText {
  position: relative;
  color: red;
  top: -8px;
  padding-top: 5px;
  padding-left: 10px;
  font-size: 12px;

  cursor: default;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
