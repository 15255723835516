.greeting {
  font-size: 18px;
}

.schedules {
  width: 100%;
}

.aboutPic {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.aboutText {
  padding-top: calc(var(--spacing) / 2);
}
