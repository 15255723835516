.selector {
  width: 100%;
  height: 40px;

  margin-bottom: 10px;
  background-color: transparent;
  outline: transparent;

  padding: 0px 7px;

  font-size: 18px;
  color: black;

  border: 1px solid var(--light);
  border-radius: 5px;
}
