.container {
  width: 100%;
  position: relative;
}

.img {
  position: absolute;
  height: inherit;
  width: 100%;
  object-fit: cover;
}

.dot {
  background-color: var(--grey);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.dotActive {
  background-color: var(--blue);
}

.dotsContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  bottom: 30px;
}

.left,
.right {
  position: absolute;
  opacity: 0;
  width: 150px;
  height: 100%;
  transition: 0.5s all ease;
  background: rgb(0, 0, 0);
}

.left:hover,
.right:hover {
  opacity: 0.3;
  transition: 0.5s all ease;
}

.left {
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.8155462868741247) 40%,
    rgba(0, 0, 0, 0.5550420851934523) 60%,
    rgba(0, 0, 0, 0.29733900396095936) 80%,
    rgba(0, 0, 0, 0) 100%
  );
}

.right {
  right: 0;
  background: linear-gradient(
    -90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.8155462868741247) 40%,
    rgba(0, 0, 0, 0.5550420851934523) 60%,
    rgba(0, 0, 0, 0.29733900396095936) 80%,
    rgba(0, 0, 0, 0) 100%
  );
}
