.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.container:not(:first-child) {
  margin-top: 30px;
}

.container:not(:last-child) {
  margin-bottom: 30px;
}

.caption {
  font-size: 14px;
  color: var(--grey);
}

