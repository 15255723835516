.container {
  display: flex;
  justify-content: space-between;
  height: 35px;
  padding: 30px 0px;
  gap: 48px;
}

.container:first-child {
  padding-top: 0px;
}

.label {
  display: flex;
  gap: 40px;
  font-size: 18px;
  width: 150px;
}

.no {
  font-weight: 400;
  font-size: 18px;
  color: #828282;
}

.name {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}

.note {
  text-align: right;
  font-weight: 400;
  color: #828282;
}
