.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.container > * {
  width: 100%;
}

.titled > *:first-child {
  width: 33%;
}

.titled > *:last-child {
  width: 67%;
}

.es > *:first-child {
  width: 50%;
}

.es > *:last-child {
  width: 50%;
}


@media (max-width: 960px) {
  .container > * {
    min-width: 90%;
    width: 90%;
  }
  .tfw > * {
    width: 100%;
  }
}
