.container {
  width: 100%;
  height: inherit;
}

.map {
  width: 100%;
  height: calc(100vh - 180px);
  min-height: 200px;
  max-height: 750px;
}

@media (max-width: 960px) {
  .map {
    height: calc(100vh - 400px);
  }
}
