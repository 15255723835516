.container {
  display: flex;
  justify-content: center;
}

.submit {
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  padding: 5px 10px;
  border: 1px solid var(--blue);
  color: var(--blue);
  border-radius: 35px;
  font-size: 18px;
  transition: 100ms;
}

.submit:hover {
  background-color: #e7f1ff;
  transition: 100ms;
}

.submit:active {
  background-color: #ddebff;
  transition: 100ms;
}

.submit:disabled {
  background-color: #f2f2f2;
  color: #bdbdbd;
  border: 1px solid #f2f2f2;
  transition: 100ms;
}

.onClick {
  width: 40px;
  background-color: transparent !important;
  border: 3px solid #bbbbbb !important;
  font-size: 0;
  border-left-color: var(--blue) !important;
  -webkit-animation: rotating 2s 0.25s linear infinite;
  animation: rotating 2s 0.25s linear infinite;
}

@-webkit-keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
