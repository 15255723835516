.container {
  width: 100%;
  font-size: 12px;
  color: var(--grey);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: var(--spacing);
  padding-bottom: 10px;
}

.info > * {
  display: flex;
  align-items: center;
  gap: 5px;
}

.info {
  display: flex;
  justify-content: center;
  gap: 20px;
  white-space: nowrap;
}

.icon {
  width: 18px;
}

.container a {
  color: var(--grey);
  text-decoration: none;
}

.container a:hover {
  color: var(--blue);
  transition: 500ms;
}

@media (max-width: 400px) {
  .container {
    visibility: hidden;
  }
}

.me {
  color: #bfbfbf !important;
}

.me:hover {
  color: var(--blue) !important;
}
