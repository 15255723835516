.container {
  display: grid;
  grid-template-rows: auto auto 600px;
  grid-template-columns: 70fr auto;
  column-gap: 20px;
  row-gap: 30px;
}

.titleContainer {
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  align-items: flex-end;
}

.title {
  font-size: 24px;
  color: var(--grey);
}

.button {
  color: var(--blue);
  cursor: pointer;

  display: flex;
  gap: 5px;
  align-items: flex-end;
}

.buttonContainer {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.caption {
  grid-area: 2 / 1 / 3 / 2;
}

.img {
  grid-area: 3 / 1 / 4 / 3;
  width: 100%;
  height: 600px;
  object-fit: cover;
}

@media (max-width: 960px) {
  .caption {
    grid-area: 2 / 1 / 3 / 3;
  }
}

@media (max-width: 450px) {
  .container {
    grid-template-rows: auto auto auto 600px;
    grid-template-columns: 100fr;
  }

  .titleContainer {
    grid-area: 1 / 1 / 2 / 2;
  }

  .caption {
    grid-area: 2 / 1 / 3 / 2;
  }

  .buttonContainer {
    grid-area: 3 / 1 / 4 / 2;
    justify-content: flex-start;
  }

  .img {
    grid-area: 4 / 1 / 5 / 2;
  }
}
