.container {
  display: flex;
  gap: 40px;
  padding-bottom: 26px;
}

.container:not(:first-child) {
  padding-top: 26px;
}

.container > img {
  background-color: black;
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.content {
  display: flex;
  font-size: 18px;
  flex-direction: column;
}

@media (max-width: 400px) {
  .container {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.name {
  line-height: 80%;
  font-weight: 400;
  margin-bottom: 20px;
  color: #828282;
}

.note {
  text-align: justify;
  font-weight: 400;
  color: #000000;
}
