.container {
  position: relative;
  width: 100%;
  height: 40px;
  padding-bottom: 10px;
}

.picker {
  z-index: 2;
  opacity: 0;
  width: 100%;
}

.output {
  background-color: transparent;

  box-sizing: border-box;
  font-size: 18px;

  padding: 0px 7px;

  width: 100%;
  height: inherit;

  border: 1px solid var(--light);
  border-radius: 5px;

  position: absolute;
  top: 0;
  left: 0;
}

.output:disabled {
  color: black;
}

.container * {
  font-family: Inter;
}
